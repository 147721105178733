<template>
  <section class="sectionAbout">
    <h2>{{ $t('headers.about') }}</h2>
    <img
      class="pictureMembers"
      src="@/assets/images/band-art/Begravd_Bones.png"
      alt="To Dust Band"
    />
    <div class="members">
      <div class="member">
        Fredrik Joakimsson - {{ $t('view.about.guitar') }} & {{ $t('view.about.bass-guitar') }}
      </div>
      <div class="member">
        Kristoffer Schultz - {{ $t('view.about.guitar') }} & {{ $t('view.about.bass-guitar') }}
      </div>
      <div class="member">
        Adonis Fredrik - {{ $t('view.about.vocals') }}
      </div>
    </div>
    <p class="readFriendlyLayout--align-left">{{ $t('view.about.band-description') }}</p>
  </section>
</template>
